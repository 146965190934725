<div class="box">
  <div class="consumers-list">
    <h2>Integraciones</h2>
    <div class="tools">
      <div class="search-box">
        <mat-form-field>
          <input matInput
            placeholder="Buscar"
            (keyup)="searchKeyUp($event)" #input>
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div class="action-buttons">
        <button mat-icon-button color="primary" (click)="loadConsumers()">
          <mat-icon>autorenew</mat-icon>
        </button>
        <a mat-flat-button color="primary" [routerLink]="['/consumers', '0']">
          <mat-icon>add</mat-icon> Crear integración
        </a>
      </div>
    </div>

    <div class="table-container">
      <app-load-indicator [show]="loadingData"></app-load-indicator>

      <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</mat-header-cell>
          <mat-cell *matCellDef="let element" [ngClass]="{'inactive-row': !element.active }"> {{ element.name }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="vehiclesUniverse">
          <mat-header-cell *matHeaderCellDef class="indicator-column">Unidades</mat-header-cell>
          <mat-cell *matCellDef="let element" class="indicator-column" [ngClass]="{'inactive-row': !element.active }"> {{ element.vehiclesUniverse }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="restService">
          <mat-header-cell *matHeaderCellDef class="indicator-column">Servicio REST</mat-header-cell>
          <mat-cell *matCellDef="let element" class="indicator-column" [ngClass]="{'inactive-row': !element.active }">
            <mat-icon *ngIf="element.restService" color="primary">done</mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="soapService">
          <mat-header-cell *matHeaderCellDef class="indicator-column">Servicio SOAP</mat-header-cell>
          <mat-cell *matCellDef="let element" class="indicator-column" [ngClass]="{'inactive-row': !element.active }">
            <mat-icon *ngIf="element.soapService" color="primary">done</mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="company">
          <mat-header-cell *matHeaderCellDef class="indicator-column">Compañía</mat-header-cell>
          <mat-cell *matCellDef="let element" class="indicator-column" [ngClass]="{'inactive-row': !element.active }">
            <mat-icon *ngIf="element.tenant" color="primary">done</mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions" class="action-column">
          <mat-header-cell *matHeaderCellDef class="action-column"></mat-header-cell>
          <mat-cell *matCellDef="let element" class="action-column" [ngClass]="{'inactive-row': !element.active }">
            <button mat-icon-button
              color="primary"
              type="button"
              class="action-button"
              (click)="downloadFile($event, element)">
              <mat-icon color="primary" fontSet="fa-regular" fontIcon="fa-file-excel"></mat-icon>
            </button>
            <button mat-icon-button
              color="warn"
              type="button"
              class="action-button"
              (click)="confirmDelete($event, element)">
              <mat-icon color="warn">delete</mat-icon>
            </button>
            <button *ngIf="element.active" mat-icon-button
              color="warn"
              type="button"
              class="action-button"
              (click)="handleStatus($event, element)">
              <mat-icon color="accent" fontSet="fa-regular">pause-circle</mat-icon>
            </button>
            <button *ngIf="!element.active" mat-icon-button
              color="warn"
              type="button"
              class="action-button"
              (click)="handleStatus($event, element)">
              <mat-icon color="accent" fontSet="fa-regular">play-circle</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
        <mat-row class="element-row"
          *matRowDef="let element; columns: displayedColumns;"
          [routerLink]="['/consumers', element.id]">
        </mat-row>
      </mat-table>
    </div>

    <mat-paginator [length]="consumers.length" [pageSize]="50"></mat-paginator>
  </div>
</div>
