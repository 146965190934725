import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-load-indicator',
  templateUrl: './load-indicator.component.html',
  styleUrls: ['./load-indicator.component.scss']
})
export class LoadIndicatorComponent implements OnInit {
  @Input('show') show: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
