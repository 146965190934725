import { LeasingCompanyResponse } from './leasing-company-response.model';

export class LeasingCompany {
  id: string;
  name: string;
  plugin: {
    idTipoCobertura: number[];
    idCliente: number[];
    idVehiculo: number[];
  };
  lastModified: string;
  created: string;
  active: boolean;
  apiKeyId: string;
  coberturas?: {
    id: number;
    text: string;
  }[] = [];
  clientes?: {
    id: number;
    text: string;
  }[] = [];
  vehiculos?: {
    id: number;
    text: string;
  }[] = [];

  deserializeLeasingCompanyResponse(res: LeasingCompanyResponse): LeasingCompany {
    this.id = res.id;
    this.name = res.name;
    this.plugin = res.plugin;
    this.lastModified = res.lastModified;
    this.created = res.created;
    this.active = res.active;
    this.apiKeyId = res.apiKeyId;
    this.clientes = res.clientes;
    this.coberturas = res.coberturas;
    this.vehiculos = res.vehiculos;
    return this;
  }

  serializeLeasingCompany() {
    const postData = {
      name: this.name,
      plugin: this.plugin,
    };
    return postData;
  }
}