<div class="box">
  <app-load-indicator [show]="loading || saving"></app-load-indicator>
  <a [routerLink]="['/consumer-fields']" class="back-link">
    <span><mat-icon class="back-icon">arrow_back</mat-icon> Campos integraciones</span>
  </a>
  <div *ngIf="!loading">
    <div class="load-error page-header" *ngIf="loadError">
      <h1 class="title" color="primary">{{ loadError }}</h1>
    </div>
    <div class="field-view" *ngIf="!loadError">
      <div class="page-header">
        <h1 class="title">{{ title | uppercase }}</h1>
      </div>
      <div>
        <form class="field-form"
          [formGroup]="fieldForm"
          (ngSubmit)="onSubmit()">
          <section>
            <div class="section-title">
              <h3>Datos generales</h3>
            </div>
            <div class="section-body">
              <mat-form-field class="full-width-input">
                <mat-label>Nombre</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="name"
                  required>
              </mat-form-field>
              <br>
              <mat-form-field class="full-width-input">
                <mat-label>Descripción</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="description"
                  required>
              </mat-form-field>
            </div>
          </section>

          <section>
            <button
              mat-flat-button
              color="primary"
              type="submit"
              [disabled]="!fieldForm.valid">
              Guardar
            </button>
            <a
              mat-flat-button
              color="basic"
              [routerLink]="['/consumer-fields']">
              Cancelar
            </a>
          </section>
        </form>
      </div>
    </div>
  </div>
</div>
