import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Subscription } from 'rxjs';
import { AppService } from '../app.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isAuthenticated: boolean = false;
  username: string;
  private userSub: Subscription;

  constructor(private authService: AuthService, private appService: AppService) { }

  ngOnInit(): void {
    this.userSub = this.authService.user.subscribe(user => {
      this.isAuthenticated = !!user;
      if (user) {
        this.username = user.username;
      }
    });
  }

  onLogout() {
    this.authService.logout();
  }

  toggleSidenav() {
    this.appService.toggleSidenav();
  }

}
