import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ConsumerField } from 'src/app/shared/models/consumer-field.model';
import { ConsumerFieldsService } from '../consumer-fields.service';
import { AppService } from 'src/app/app.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { SnackbarType } from 'src/app/snackbar/snackbar.component';

@Component({
  selector: 'app-consumer-field-view',
  templateUrl: './consumer-field-view.component.html',
  styleUrls: ['./consumer-field-view.component.scss']
})
export class ConsumerFieldViewComponent implements OnInit, OnDestroy {
  fieldForm: FormGroup;
  paramsSub: Subscription;
  fetchFieldSub: Subscription;
  field: ConsumerField;
  loadError: string;
  loading: boolean;
  saving: boolean;
  isNew: boolean = true;
  title: string = 'Crear campo para integración';

  constructor(
    private consumerFieldsService: ConsumerFieldsService,
    private appService: AppService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.paramsSub = this.route.params.subscribe(
      (params: Params) => {
        const fieldId = params['id'];
        if (fieldId !== '0') {
          this.isNew = false;
          this.loading = true;
          this.title = 'Editar campo para integración';
          this.fetchFieldSub = this.consumerFieldsService.getField(fieldId).subscribe((field: ConsumerField) => {
            this.loading = false;
            this.field = field;
            this.initForm();
          }, (err) => {
            if (err.status === 404) {
              this.router.navigate(['/404']);
            }
            this.loading = false;
            this.loadError = 'Error al cargar los datos';
          });
        } else {
          this.field = new ConsumerField();
          this.initForm();
        }
      }
    );
  }

  ngOnDestroy() {
    this.paramsSub.unsubscribe();
    if (this.fetchFieldSub) {
      this.fetchFieldSub.unsubscribe();
    }
  }

  initForm() {
    this.fieldForm = new FormGroup({
      name: new FormControl(this.field.fieldName, Validators.required),
      description: new FormControl(this.field.description, Validators.required)
    });
  }

  onSubmit() {
    if (this.fieldForm.valid) {
      const formValue = this.fieldForm.value;
      let postData = new ConsumerField();
      postData.id = this.field.id;
      postData.fieldName = formValue.name;
      postData.description = formValue.description;

      this.saving = true;
      if (this.isNew) {
        this.consumerFieldsService.createField(postData).subscribe((res) => {
          this.saving = false;
          const message = `Campo ${ this.field.fieldName } creado.`;
          this.appService.showSnackBar(message, SnackbarType.SUCCESS);
          this.router.navigate(['/consumer-fields']);
        }, (err) => {
          this.saving = false;
          const message = `Error al crear el campo ${ this.field.fieldName }.`;
          this.appService.showSnackBar(message, SnackbarType.ERROR);
        });
      } else {
        this.consumerFieldsService.updateField(postData).subscribe((res) => {
          this.saving = false;
          const message = `Campo ${ this.field.fieldName } actualizado.`;
          this.appService.showSnackBar(message, SnackbarType.SUCCESS);
          this.router.navigate(['/consumer-fields']);
        }, (err) => {
          this.saving = false;
          const message = `Error al actualizar el campo ${ this.field.fieldName }.`;
          this.appService.showSnackBar(message, SnackbarType.ERROR);
        });
      }
    }
  }
}
