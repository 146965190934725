import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Company } from '../shared/models/company.model';
import { map } from 'rxjs/operators';
import { CompanyResponse } from '../shared/models/company-response.model';

@Injectable({ providedIn: 'root' })
export class CompaniesService {
  constructor(private http: HttpClient) {}

  getCompanies() {
    return this.http.get<Company[]>(`${ environment.apiUrl }/admin/tenants/`).pipe(
      map((res: any[]) => {
        const companies: Company[] = [];
        if (res) {
          res.map(c => companies.push(new Company().desrializeCompanyResponse(c)));
        }
        return companies;
      })
    );
  }

  getCompany(id: string) {
    return this.http.get(`${ environment.apiUrl }/admin/tenants/${ id }/`).pipe(
      map((res: CompanyResponse) => new Company().desrializeCompanyResponse(res))
    );
  }

  createCompany(company: Company) {
    return this.http.post(`${ environment.apiUrl }/admin/tenants/create/`, company.serializeCompanyResponse());
  }

  updateCompany(company: Company) {
    return this.http.patch(`${ environment.apiUrl }/admin/tenants/${ company.id }/`, company.serializeCompanyResponse());
  }

  deleteCompany(companyId: string) {
    return this.http.delete(`${ environment.apiUrl }/admin/tenants/${ companyId }/`);
  }
}
