import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarComponent, SnackbarType } from './snackbar/snackbar.component';

@Injectable({ providedIn: 'root' })
export class AppService {
  private showSidenav: BehaviorSubject<boolean> = new BehaviorSubject (true);
  showSidenav$ = this.showSidenav.asObservable();

  constructor(private messageSnackBar: MatSnackBar) {}

  toggleSidenav() {
    this.showSidenav.next(!this.showSidenav.getValue());
  }

  showSnackBar(message: string, type: SnackbarType) {
    let data: any = { text: message, type };
    let config = new MatSnackBarConfig();
    config.duration = 6000
    config.data = data;
    this.messageSnackBar.openFromComponent(SnackbarComponent, config);
  }
}
