import { InsuranceResponse } from './insurance-response.model';

export class Insurance {
  id: number;
  nombre: string;

  deserializeInsuranceResponse(res: InsuranceResponse) {
    this.id = res.id;
    this.nombre = res.text;
  }
}