import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../shared/models/user.model';

interface AuthResponseModel {
  id: number;
  username: string;
  email: string;
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user = new BehaviorSubject<User>(null);

  constructor(private http: HttpClient, private router: Router) {}

  login(username: string, password: string) {
    return this.http.post<AuthResponseModel>(`${environment.apiUrl}/login/`, {
      username,
      password
    })
    .pipe(
      catchError(this.handleError),
      tap(res => this.handleAuthentication(+res.id, res.username, res.email, res.token))
    );
  }

  autoLogin() {
    if (!localStorage.getItem('b2bAdminAuth')) {
      return;
    }
    const { id, username, email, token } = JSON.parse(localStorage.getItem('b2bAdminAuth'));
    const localStorageUser = new User().deserialize({ id, username, email, token });
    if (localStorageUser.token) {
      this.user.next(localStorageUser);
    }
  }

  logout() {
    this.user.next(null);
    localStorage.removeItem('b2bAdminAuth');
    this.router.navigate(['/login']);
  }

  private handleAuthentication(userId: number, username: string, email: string, token: string) {
    const user = new User().deserialize({ userId, username, email, token });
    this.user.next(user);
    localStorage.setItem('b2bAdminAuth', JSON.stringify(user));
  }

  private handleError(error) {
    let errorMessage = 'Error al iniciar sesión.';
    if (error.status == 400) {
      errorMessage = 'Credenciales incorrectas.';
    }
    return throwError(errorMessage);
  }
}
