// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  avlApiUrl: 'https://api-avl.eps-encontrack.com/v1',
  avlToken: 'XU2ws45ZDvioRAHk7tHr',
  apiUrl: 'https://b2b.eps-encontrack.com/api',
  b2bApiGatewayUrl: 'https://1tt1datsvi.execute-api.us-east-1.amazonaws.com/v1',
  // apiUrl: 'http://localhost:8000/api'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
