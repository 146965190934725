<div>
  <h2 mat-dialog-title>Confirmación</h2>
  <mat-dialog-content>
    <div class="box">
      {{ message }}
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">Cancelar</button>
    <button mat-button [mat-dialog-close]="true">Aceptar</button>
  </mat-dialog-actions>
</div>
