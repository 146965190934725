import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Company } from '../shared/models/company.model';
import { CompaniesService } from './companies.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { SnackbarType } from '../snackbar/snackbar.component';
import { AppService } from '../app.service';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<Company>;
  companies: Company[] = [];
  displayedColumns: string[] = ['name', 'topic', 'uuid', 'actions'];
  loadingData: boolean;

  constructor(
    private companiesService: CompaniesService,
    public confirmationDialog: MatDialog,
    private appService: AppService
  ) { }

  ngOnInit(): void {
    this.loadCompanies();
  }

  ngOnDestroy() {}

  loadCompanies() {
    this.loadingData = true;
    this.companiesService.getCompanies().subscribe(companies => {
      this.loadingData = false;
      this.companies = companies;
      this.dataSource = new MatTableDataSource(this.companies);
      this.dataSource.paginator = this.paginator;
    }, err => {
      this.loadingData = false;
    });
  }

  confirmDelete(evt, company: Company) {
    event.stopPropagation();
    const dialogRef = this.confirmationDialog.open(ConfirmationDialogComponent, {
      data: `¿Desea eliminar la companía ${ company.name }?`
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.deleteCompany(company);
      }
    });
  }

  deleteCompany(company: Company) {
    this.loadingData = true;
    this.companiesService.deleteCompany(company.id).subscribe((res) => {
      this.loadingData = false;
      const companyName = company.name;
      const index = this.dataSource.data.indexOf(company);
      this.dataSource.data.splice(index, 1);
      this.dataSource._updateChangeSubscription();
      const message = `La compañía ${ companyName } ha sido eliminada.`;
      this.appService.showSnackBar(message, SnackbarType.SUCCESS);
    }, (err) => {
      this.loadingData = false;
      const message = `Error al eliminar ${ company.name }.`;
      this.appService.showSnackBar(message, SnackbarType.ERROR);
    });
  }

  searchKeyUp(event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
