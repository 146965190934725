import { ConsumerResponse } from './consumer-response.model';
import { Company } from './company.model';
import { Vehicle } from './vehicle.model';
import { Client } from './client.model';
import { Insurance } from './insurance.model';
import { ConsumerField } from './consumer-field.model';

export class Consumer {
  // General data
  id: string;
  name: string;
  uuidCode: string;
  undeterminatedAccess: boolean = true;
  startDate: string;
  endDate: string;
  evidenceService: boolean;
  restService: boolean;
  soapService: boolean;
  timeFormat: string;
  cleanLicensePlate: boolean;
  vehiclesUniverse: number;

  // Consume report
  dayRate: number;
  monthRate: number;
  threeMonthsRate: number;
  monthConsume: number;
  threeMonthsConsume: number;

  // Info
  created: string;
  lastModified: string;
  mbRate: number = 0;
  requestRate: number = 0;

  // Assigned units
  clients: Client[] = [];
  insurances: Insurance[] = [];
  vehicles: Vehicle[] = [];
  responseFields: string[] =[];

  // Assigned tenants
  tenant: string;
  serviceParameters: { key: string, value: string }[] = [];

  // API keys
  apiKeys: { description: string, secretKey: string }[] = [];

  active: boolean;

  deserializeConsumerResponse(res: ConsumerResponse): this {
    this.id = res.id;
    this.name = res.name;
    this.uuidCode = res.uuid_code;
    this.undeterminatedAccess = res.undeterminated_access;
    this.startDate = res.start_date;
    this.endDate = res.end_date
    this.created = res.created;
    this.evidenceService = res.evidence_service;
    this.restService = res.rest_service;
    this.soapService = res.soap_service;
    this.lastModified = res.last_modified;
    this.mbRate = res.mb_rate || 0;
    this.requestRate = res.request_rate || 0;
    this.timeFormat = res.time_format;
    this.cleanLicensePlate = res.clean_license_plate;
    this.tenant = res.tenant;
    this.responseFields = res.response_fields || [];
    this.dayRate = res.day_rate_val;
    this.monthRate = res.month_rate_val;
    this.threeMonthsRate = res.three_months_rate_val;
    this.monthConsume = res.month_consume_val;
    this.threeMonthsConsume = res.three_months_consume_val;
    this.vehiclesUniverse = res.vehicles_universe;
    this.active = res.active;

    if (res.vehicles_list) {
      res.vehicles_list.map(v => this.vehicles.push(new Vehicle().deserializeVehicleResponse(v)));
    }

    if (res.clients_list) {
      res.clients_list.map(c => {
        const client = new Client();
        client.deserializeClientResponse(c);
        this.clients.push(client);
      });
    }

    if (res.insurances_list) {
      res.insurances_list.map(c => {
        const insurance = new Insurance();
        insurance.deserializeInsuranceResponse(c);
        this.insurances.push(insurance);
      });
    }

    if (res.api_keys) {
      res.api_keys.map((ak: any) => {
        this.apiKeys.push({
          description: ak.description,
          secretKey: ak.secret_key
        });
      });
    }

    const serviceParameters = res.service_parameters ? JSON.parse(res.service_parameters) : [];

    serviceParameters.map(s => {
      const keys = Object.keys(s);
      const serviceParam = {
        key: keys[0],
        value: s[keys[0]]
      };
      this.serviceParameters.push(serviceParam);
    });

    return this;
  }

  serializeConsumer() {
    const postData = {};

    postData['name'] = this.name;
    postData['undeterminated_access'] = this.undeterminatedAccess || false;
    postData['start_date'] = this.startDate || null;
    postData['end_date'] = this.endDate || null;
    postData['rest_service'] = this.restService || false;
    postData['soap_service'] = this.soapService || false;
    postData['evidence_service'] = this.evidenceService || false;
    postData['request_rate'] = this.requestRate || 0;
    postData['mb_rate'] = this.mbRate || 0;
    postData['time_format'] = this.timeFormat;
    postData['clean_license_plate'] = this.cleanLicensePlate || false;
    postData['insurances'] = this.insurances.map(obj => obj.id);
    postData['clients'] = this.clients.map(obj => obj.id);
    postData['vehicles'] = this.vehicles.map(obj => obj.id);
    postData['response_fields'] = this.responseFields;
    postData['tenant'] = this.tenant || null;
    let parsedServiceParams = [];
    this.serviceParameters.forEach(sp => {
      let parsedParam = {};
      parsedParam[sp.key] = sp.value;
      parsedServiceParams.push(parsedParam);
    });
    postData['service_parameters'] = JSON.stringify(parsedServiceParams);
    return postData;
  }
}
