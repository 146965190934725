import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModules } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { AuthComponent } from './auth/auth.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptorService } from './auth/auth-interceptor.service';
import { CompaniesComponent } from './companies/companies.component';
import { LoadIndicatorComponent } from './load-indicator/load-indicator.component';
import { CompanyViewComponent } from './companies/company-view/company-view.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConsumersComponent } from './consumers/consumers.component';
import { ConsumerViewComponent } from './consumers/consumer-view/consumer-view.component';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { ConsumerFieldsComponent } from './consumer-fields/consumer-fields.component';
import { ConsumerFieldViewComponent } from './consumer-fields/consumer-field-view/consumer-field-view.component';
import { LeasingCompaniesComponent } from './leasing-companies/leasing-companies.component';
import { LeasingCompanyViewComponent } from './leasing-companies/leasing-company-view/leasing-company-view.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AuthComponent,
    CompaniesComponent,
    LoadIndicatorComponent,
    CompanyViewComponent,
    PageNotFoundComponent,
    ConfirmationDialogComponent,
    ConsumersComponent,
    ConsumerViewComponent,
    SnackbarComponent,
    ConsumerFieldsComponent,
    ConsumerFieldViewComponent,
    LeasingCompaniesComponent,
    LeasingCompanyViewComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModules,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
