import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Subscription } from 'rxjs';
import { AppService } from './app.service';
import { NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isAuthenticated: boolean = false;
  showSidenav: boolean;
  title: string = 'Encontrack B2B';
  private userSub: Subscription;

  constructor(
    private authService: AuthService,
    private appService: AppService,
    private router: Router,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.authService.autoLogin();
    this.userSub = this.authService.user.subscribe((user) => {
      this.isAuthenticated = !!user;
    });

    this.appService.showSidenav$.subscribe((open) => (this.showSidenav = open));

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.router)
      )
      .subscribe(() => {
        const pageTitles = this.getTitle(this.router.routerState, this.router.routerState.root);
        let title = this.title;
        if(pageTitles.length) title = `${title} - ${pageTitles.join(' - ')}`;
        this.titleService.setTitle(title);
      });
  }
  getTitle(state: any, parent: any): string[] {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
}
