import { Deserializable } from './deserializable.model';
import { CompanyResponse } from './company-response.model';

export class Company implements Deserializable {
  id: string;
  name: string;
  kafkaTopic: string;
  serviceUrl: string;
  uuidCode: string;
  kafkaServers: string[] = [];
  serviceParameters: { key: string, value: string }[] = [];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  desrializeCompanyResponse(res: CompanyResponse): this {
    const serviceParameters = res.service_parameters ? JSON.parse(res.service_parameters) : [];
    this.id = res.id;
    this.name =  res.name;
    this.kafkaTopic = res.kafka_topic;
    this.serviceUrl = res.service_url;
    this.uuidCode = res.uuid_code;
    this.kafkaServers = res.kafka_servers ? res.kafka_servers.split(',') : [];
    serviceParameters.map(s => {
      const keys = Object.keys(s);
      const serviceParam = {
        key: keys[0],
        value: s[keys[0]]
      };
      this.serviceParameters.push(serviceParam);
    });
    return this;
  }

  serializeCompanyResponse() {
    const postData: CompanyResponse = {
      'id': this.id,
      'name': this.name,
      'kafka_topic': this.kafkaTopic,
      'service_url': this.serviceUrl,
      'kafka_servers': '',
      'service_parameters': ''
    };

    if (this.kafkaServers) {
      postData['kafka_servers'] = this.kafkaServers.join();
    }

    if (this.serviceParameters) {
      const servicesArr = [];
      this.serviceParameters.map(s => {
        const serviceObj = {};
        serviceObj[s.key] = s.value;
        servicesArr.push(serviceObj);
      });
      postData['service_parameters'] = JSON.stringify(servicesArr);
    }
    return postData;
  }
}
