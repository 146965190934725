<div class="b2b-admin-app">
  <div class="header" *ngIf="isAuthenticated">
    <app-header></app-header>
  </div>
  <mat-sidenav-container class="body">
    <mat-sidenav
      class="b2b-sidenav"
      mode="side"
      [opened]="showSidenav"
      *ngIf="isAuthenticated">
      <mat-nav-list role="list">
        <a mat-list-item [routerLink]="['/companies']">Compañías</a>
        <a mat-list-item [routerLink]="['/consumers']">Integraciones</a>
        <a mat-list-item [routerLink]="['/consumer-fields']">Campos integraciones</a>
        <a mat-list-item [routerLink]="['/leasing-companies']">Arrendadoras</a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="main-content" [ngClass]="{'main-content-no-padding': !isAuthenticated}">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
