<div class="app-header">
  <mat-toolbar color="primary">
    <button mat-icon-button (click)="toggleSidenav()">
      <mat-icon>menu</mat-icon>
    </button>
    <a [routerLink]="['/']">
      <img src="assets/img/logo_white.png" alt="B2B" class="logo">
    </a>
    <span class="spacer"></span>
    <span class="header-user" *ngIf="isAuthenticated">Usuario: {{ username }}</span>
    <button mat-flat-button color="primary" *ngIf="isAuthenticated" (click)='onLogout()'>Salir</button>
  </mat-toolbar>
</div>
