import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConsumerField } from '../shared/models/consumer-field.model';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '../app.service';
import { ConsumerFieldsService } from './consumer-fields.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { SnackbarType } from '../snackbar/snackbar.component';


@Component({
  selector: 'app-consumer-fields',
  templateUrl: './consumer-fields.component.html',
  styleUrls: ['./consumer-fields.component.scss']
})
export class ConsumerFieldsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<ConsumerField>;
  fields: ConsumerField[] = [];
  displayedColumns: string[] = ['name', 'description', 'actions'];
  loadingData: boolean;

  constructor(
    public confirmationDialog: MatDialog,
    private appService: AppService,
    private consumerFieldsService: ConsumerFieldsService
  ) { }

  ngOnInit(): void {
    this.loadFields();
  }

  ngOnDestroy() {}

  loadFields() {
    this.loadingData = true;
    this.consumerFieldsService.getFields().subscribe(fields => {
      this.loadingData = false;
      this.fields = fields;
      this.dataSource = new MatTableDataSource(this.fields);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, err => {
      this.loadingData = false;
    });
  }

  confirmDelete(evt, field: ConsumerField) {
    event.stopPropagation();
    const dialogRef = this.confirmationDialog.open(ConfirmationDialogComponent, {
      data: `¿Desea eliminar el campo ${ field.fieldName }?`
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.deleteField(field);
      }
    });
  }

  deleteField(field: ConsumerField) {
    this.loadingData = true;
    this.consumerFieldsService.deleteField(field.id).subscribe((res) => {
      this.loadingData = false;
      const fieldName = field.fieldName;
      const index = this.dataSource.data.indexOf(field);
      this.dataSource.data.splice(index, 1);
      this.dataSource._updateChangeSubscription();
      const message = `El campo ${ fieldName } ha sido eliminado.`;
      this.appService.showSnackBar(message, SnackbarType.SUCCESS);
    }, (err) => {
      this.loadingData = false;
      const message = `Error al eliminar ${ field.fieldName }.`;
      this.appService.showSnackBar(message, SnackbarType.ERROR);
    });
  }

  searchKeyUp(event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
