import { VehicleResponse } from './vehicle-response.model';

export class Vehicle {
  id: number;
  text: string;

  deserializeVehicleResponse(res: VehicleResponse) {
    this.id = res.id;
    this.text = res.text;
    return this;
  }
}
