<div class="box">
  <app-load-indicator [show]="loading || saving"></app-load-indicator>
  <a [routerLink]="['/companies']" class="back-link">
    <span><mat-icon class="back-icon">arrow_back</mat-icon> Compañías</span>
  </a>
  <div *ngIf="!loading">
    <div class="load-error page-header" *ngIf="loadError">
      <h1 class="title" color="primary">{{ loadError }}</h1>
    </div>
    <div class="company-view" *ngIf="!loadError">
      <div class="page-header">
        <h1 class="title">{{ title | uppercase }}</h1>
      </div>
      <div>
        <form class="company-form"
          [formGroup]="companyForm"
          (ngSubmit)="onSubmit()">
        <!-- <form  #companyForm="ngForm" (ngSubmit)="onSubmit()"> -->
          <section>
            <div class="section-title">
              <h3>Datos generales</h3>
            </div>
            <div class="section-body">
              <mat-form-field class="full-width-input">
                <mat-label>Nombre</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="name"
                  required>
              </mat-form-field>
              <br>
              <mat-form-field class="full-width-input">
                <mat-label>URL</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="serviceUrl">
              </mat-form-field>
              <br>
              <mat-form-field class="full-width-input">
                <mat-label>Tópico de Kafka</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="kafkaTopic">
              </mat-form-field>
            </div>
          </section>

          <section formArrayName="kafkaServers">
            <div class="section-title">
              <h3>Servidores Kafka</h3>
            </div>
            <div class="section-body">
              <div class="kafka-server" *ngFor="let serverControl of getServerControls(); let i = index;">
                <h4>Servidor {{ i + 1 }}</h4>
                <div>
                  <mat-form-field class="full-width-input">
                    <mat-label>Nombre servidor</mat-label>
                    <input
                      type="text"
                      matInput
                      [formControlName]="i">
                  </mat-form-field>
                  <button
                    type="button"
                    mat-flat-button
                    color="warn"
                    (click)="removeServer(i)">
                    Eliminar
                  </button>
                </div>
              </div>
              <button
                (click)="addServer('', true)"
                mat-button
                color="primary"
                type="button">
                <mat-icon>add</mat-icon> Agregar
              </button>
            </div>
          </section>

          <section formArrayName="serviceParameters">
            <div class="section-title">
              <h3>Parámetros de Servicio</h3>
            </div>
            <div class="section-body">
              <div class="service-parameter" *ngFor="let serviceParamControl of getServiceParamsControls(); let i = index;">
                <h4>Parámetro {{ i + 1 }}</h4>
                <div [formGroupName]="i">
                  <div class="service-parameter-inputs">
                    <mat-form-field>
                      <mat-label>Nombre parámetro</mat-label>
                      <input
                        type="text"
                        matInput
                        formControlName="key">
                    </mat-form-field>
                    <span class="separator"></span>
                    <mat-form-field>
                      <mat-label>Valor a mostrar</mat-label>
                      <input
                        type="text"
                        matInput
                        formControlName="value">
                    </mat-form-field>
                  </div>
                  <button
                    type="button"
                    mat-flat-button
                    color="warn"
                    (click)="removeServiceParameter(i)">
                    Eliminar
                  </button>
                </div>
              </div>
              <button
                (click)="addServiceParameter()"
                mat-button
                color="primary"
                type="button">
                <mat-icon>add</mat-icon> Agregar
              </button>
            </div>
          </section>

          <section>
            <button
              mat-flat-button
              color="primary"
              type="submit"
              [disabled]="!companyForm.valid">
              Guardar
            </button>
            <a
              mat-flat-button
              color="basic"
              [routerLink]="['/companies']">
              Cancelar
            </a>
          </section>
        </form>
      </div>
    </div>
  </div>
</div>
