import { HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { take, exhaustMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return this.authService.user.pipe(
      take(1),
      exhaustMap(user => {
        if (request.url.startsWith(environment.avlApiUrl)) {
          return next.handle(request);
        }
        if (!user) {
          return next.handle(request);
        }
        if (request.url.startsWith(environment.b2bApiGatewayUrl)) {
          const authRequest = request.clone({
            headers: new HttpHeaders({
              'x-api-key': 'tZBVNXFE6F7qjEDMzxonX469kHxB94r71T9wpR1S',
            })
          });
          return next.handle(authRequest);
        } else {
          const authRequest = request.clone({
            headers: new HttpHeaders({
              'Authorization': `Token ${ user.token }`,
            })
          });
          return next.handle(authRequest);
        }
      })
    );
  }
}
