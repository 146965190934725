<div class="box">
  <div class="companies-list">
    <h2>Compañías</h2>
    <div class="tools">
      <div class="search-box">
        <mat-form-field>
          <input matInput placeholder="Buscar"
            (keyup)="searchKeyUp($event)">
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div class="action-buttons">
        <button mat-icon-button color="primary" (click)="loadCompanies()">
          <mat-icon>autorenew</mat-icon>
        </button>
        <a mat-flat-button color="primary" [routerLink]="['/companies', '0']">
          <mat-icon>add</mat-icon> Crear compañía
        </a>
      </div>
    </div>

    <div class="table-container">
      <app-load-indicator [show]="loadingData"></app-load-indicator>

      <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>Nombre</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="topic">
          <mat-header-cell *matHeaderCellDef>Tópico</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.kafkaTopic }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="uuid">
          <mat-header-cell *matHeaderCellDef>UUID</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.uuidCode }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions" class="action-column">
          <mat-header-cell *matHeaderCellDef class="action-column"></mat-header-cell>
          <mat-cell *matCellDef="let element" class="action-column">
            <!-- <a class="action-button" [routerLink]="['/companies', element.id]">
              <mat-icon color="primary">edit</mat-icon>
            </a> -->
            <button mat-icon-button
              color="warn"
              type="button"
              class="action-button"
              (click)="confirmDelete($event, element)">
              <mat-icon color="warn">delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
        <mat-row class="element-row"
          *matRowDef="let element; columns: displayedColumns;"
          [routerLink]="['/companies', element.id]">
        </mat-row>
      </mat-table>
    </div>

    <mat-paginator [length]="companies.length" [pageSize]="50"></mat-paginator>
  </div>
</div>
