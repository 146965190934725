import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Consumer } from '../shared/models/consumer.model';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { LeasingCompany } from '../shared/models/leasing-company.model';
import { LeasingCompanyResponse } from '../shared/models/leasing-company-response.model';

@Injectable({ providedIn: 'root' })
export class LeasingCompaniesService {
  constructor(private http: HttpClient) {}

  getLeasingCompanies() {
    let params = new HttpParams();
    params = params.append('limit', '500');
    return this.http.get<Consumer[]>(
      `${ environment.b2bApiGatewayUrl }/consumers/`,
      { params }
    ).pipe(
      map((res: any) => {
        const leasingCompanies: LeasingCompany[] = [];
        if (res) {
          res.data.map(c => leasingCompanies.push(
            new LeasingCompany().deserializeLeasingCompanyResponse(c))
          );
        }
        return leasingCompanies;
      })
    );
  }

  getLeasingCompany(id: string) {
    return this.http.get(`${ environment.b2bApiGatewayUrl }/consumers/${ id }/`).pipe(
      map((res: { data: LeasingCompanyResponse }) => {
        return new LeasingCompany().deserializeLeasingCompanyResponse(res.data)
      })
    );
  }

  createLeasingCompany(leasingCompany: LeasingCompany) {
    return this.http.post(
      `${ environment.b2bApiGatewayUrl }/consumers/`,
      leasingCompany.serializeLeasingCompany()
    );
  }

  updateLeasingCompany(leasingCompany: LeasingCompany) {
    return this.http.patch(
      `${ environment.b2bApiGatewayUrl }/consumers/${ leasingCompany.id }/`,
      leasingCompany.serializeLeasingCompany()
    );
  }

  deleteLeasingCompany(companyId: string) {
    return this.http.delete(
      `${ environment.b2bApiGatewayUrl }/consumers/${ companyId }/`
    );
  }
}
