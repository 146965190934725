import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

export enum SnackbarType {
  SUCCESS = 'success',
  ERROR = 'error',
  NORMAL = 'normal'
}

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public sbRef: MatSnackBarRef<SnackbarComponent>
  ) {}

  ngOnInit(): void {
    this.data.snackbarClass = 'basic-snackbar';

    if (this.data.type === SnackbarType.SUCCESS) {
      this.data.snackbarClass = 'success-snackbar';
    }

    if (this.data.type === SnackbarType.ERROR) {
      this.data.snackbarClass = 'error-snackbar';
    }
  }

}
