import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { ConsumerFieldResponse } from '../shared/models/consumer-field-response.model';
import { ConsumerField } from '../shared/models/consumer-field.model';

@Injectable({ providedIn: 'root' })
export class ConsumerFieldsService {
  constructor(private http: HttpClient) {}

  getFields() {
    return this.http.get(`${ environment.apiUrl }/admin/consumer_fields/`).pipe(
      map((res: ConsumerFieldResponse[]) => {
        const fields = [];
        res.map(f => {
          const field = {
            id: f.id,
            description: f.description,
            fieldName: f.field_name
          };
          fields.push(field);
        });

        return fields;
      })
    );
  }

  getField(id: string) {
    return this.http.get(`${ environment.apiUrl }/admin/consumer_fields/${ id }/`).pipe(
      map((res: ConsumerFieldResponse) => new ConsumerField().desrializeConsumerFieldResponse(res))
    );
  }

  createField(field: ConsumerField) {
    return this.http.post(`${ environment.apiUrl }/admin/consumer_fields/create/`, field.serializeField());
  }

  updateField(field: ConsumerField) {
    return this.http.patch(`${ environment.apiUrl }/admin/consumer_fields/${ field.id }/`, field.serializeField());
  }

  deleteField(fieldId: string) {
    return this.http.delete(`${ environment.apiUrl }/admin/consumer_fields/${ fieldId }/`);
  }
}
