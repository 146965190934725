import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Component, OnInit, OnDestroy, ComponentFactoryResolver, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormArray, AbstractControl, Validators } from '@angular/forms';
import { Subscription, forkJoin, of } from 'rxjs';
import { Consumer } from 'src/app/shared/models/consumer.model';
import { ConsumersService } from '../consumers.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { map, debounceTime, switchMap, tap } from 'rxjs/operators';
import { Vehicle } from 'src/app/shared/models/vehicle.model';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import { Client } from 'src/app/shared/models/client.model';
import { Insurance } from 'src/app/shared/models/insurance.model';
import { ConsumerField } from 'src/app/shared/models/consumer-field.model';
import { CompaniesService } from 'src/app/companies/companies.service';
import { Company } from 'src/app/shared/models/company.model';
import { AppService } from 'src/app/app.service';
import { SnackbarType } from 'src/app/snackbar/snackbar.component';
import { ConsumerFieldsService } from 'src/app/consumer-fields/consumer-fields.service';


@Component({
  selector: 'app-consumer-view',
  templateUrl: './consumer-view.component.html',
  styleUrls: ['./consumer-view.component.scss']
})
export class ConsumerViewComponent implements OnInit, OnDestroy {
  @ViewChild('clientsInput') clientsAutocomplete: ElementRef;
  @ViewChild('insuranceInput') insuranceAutocomplete: ElementRef;
  @ViewChild('vehiclesInput') vehiclesAutocomplete: ElementRef;
  consumerForm: FormGroup;
  paramsSub: Subscription;
  fetchConsumerSub: Subscription;
  consumer: Consumer;
  loadError: string;
  loading: boolean;
  saving: boolean;
  isNew: boolean = true;
  title: string = 'Crear integración';
  separatorKeysCodes: number[] = [ENTER, COMMA];

  // API Data
  fetchingAutocompleteData: boolean = false;
  consumerFields: ConsumerField[] = [];
  companies: Company[] = [];
  dataSub: Subscription;
  filteredVehicles: Vehicle[] = [];
  filteredClients: Client[] = [];
  filteredInsurances: Insurance[] = [];
  timeFormats: { value: string, option: string }[] = [];

  onlyNumbersRegex = '^[0-9]*$';
  

  constructor(
    private consumersService: ConsumersService,
    private companiesService: CompaniesService,
    private consumerFieldsService: ConsumerFieldsService,
    private appService: AppService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.paramsSub = this.route.params.subscribe(
      (params: Params) => {
        this.loading = true;

        const fieldsReq = this.consumerFieldsService.getFields();
        const companiesReq = this.companiesService.getCompanies();
        const timeFormatsReq = this.consumersService.getTimeFormatOptions();

        this.dataSub = forkJoin([fieldsReq, companiesReq, timeFormatsReq]).subscribe(result => {
          this.consumerFields = result[0];
          this.companies = result[1];
          this.timeFormats = result[2];

          // Init new or edit consumer
          const consumerId = params['id'];
          if (consumerId !== '0') {
            this.initEditConsumer(consumerId);
          } else {
            this.initNewConsumer();
          }
        });
      }
    );
  }

  ngOnDestroy() {
    this.paramsSub.unsubscribe();
    this.dataSub.unsubscribe();
    if (this.fetchConsumerSub) {
      this.fetchConsumerSub.unsubscribe();
    }
  }

  initEditConsumer(consumerId: string) {
    this.isNew = false;
    this.title = 'Editar integración';
    this.fetchConsumerSub = this.consumersService.getConsumer(consumerId)
      .subscribe((consumer: Consumer) => {
        this.loading = false;
        this.consumer = consumer;
        this.initForm();
      }, (err) => {
        if (err.status === 404) {
          this.router.navigate(['/404']);
        }
        this.loading = false;
        this.loadError = 'Error al cargar los datos';
      });
  }

  initNewConsumer() {
    this.consumer = new Consumer();
    this.initForm();
    this.loading = false;
  }

  initForm() {
    const startDate = this.consumer.startDate ? new Date(this.consumer.startDate) : null;
    const endDate = this.consumer.endDate ? new Date(this.consumer.endDate) : null;
    const timeFormat = this.consumer.timeFormat ? this.consumer.timeFormat : this.timeFormats[0].value;
    this.consumerForm = new FormGroup({
      name: new FormControl(this.consumer.name, Validators.required),
      undeterminatedAccess: new FormControl(this.consumer.undeterminatedAccess),
      startDate: new FormControl(startDate),
      endDate: new FormControl(endDate),
      restService: new FormControl(this.consumer.restService),
      soapService: new FormControl(this.consumer.soapService),
      evidenceService: new FormControl(this.consumer.evidenceService),
      requestRate: new FormControl(this.consumer.requestRate),
      mbRate: new FormControl(this.consumer.mbRate),
      timeFormat: new FormControl(timeFormat),
      cleanLicensePlate: new FormControl(this.consumer.cleanLicensePlate),
      vehicles: new FormArray([]),
      vehiclesCtrl: new FormControl(),
      clients: new FormArray([]),
      clientsCtrl: new FormControl(),
      insurances: new FormArray([]),
      insurancesCtrl: new FormControl(),
      allResponseFields: new FormControl(),
      responseFields: new FormArray([]),
      company: new FormControl(),
      serviceParameters: new FormArray([]),
      apiKeys: new FormArray([])
    });

    // Init AVL autocompletes
    this.consumerForm.get('vehiclesCtrl').valueChanges.pipe(
      debounceTime(250),
      tap(() => {
        this.fetchingAutocompleteData = true;
      }),
      switchMap(value => {
        if (!value) {
          this.fetchingAutocompleteData = false;
          return of({ items: [] });
        }
        return this.consumersService.fetchVehiclesByTerm(value);
      })
    ).subscribe((data: { more: boolean, items: [] }) => {
      this.fetchingAutocompleteData = false;
      this.filteredVehicles = data ? data.items : [];
    }, err => null);

    this.consumerForm.get('clientsCtrl').valueChanges.pipe(
      debounceTime(250),
      tap(() => {
        this.fetchingAutocompleteData = true;
      }),
      switchMap(value => {
        if (!value) {
          this.fetchingAutocompleteData = false;
          return of({ items: [] });
        }
        return this.consumersService.fetchClientsByTerm(value)
      })
    ).subscribe((data: { more: boolean, items: [] }) => {
      this.fetchingAutocompleteData = false;
      this.filteredClients = data ? data.items : [];
    }, err => null);

    this.consumerForm.get('insurancesCtrl').valueChanges.pipe(
      debounceTime(250),
      tap(() => {
        this.fetchingAutocompleteData = true;
      }),
      switchMap(value => {
        if (!value) {
          this.fetchingAutocompleteData = false;
          return of({ items: [] });
        }
        return this.consumersService.fetchInsurancesByTerm(value);
      })
    ).subscribe((data: { more: boolean, items: [] }) => {
      this.fetchingAutocompleteData = false;
      this.filteredInsurances = data ? data.items : [];
    }, err => null);

    this.initVehiclesForm();
    this.initClientsForm();
    this.initInsurancesForm();
    this.initConsumerFields();
    this.initTenantForm();
  }

  onSubmit() {
    if (this.consumerForm.valid) {
      const formValue = this.consumerForm.value;
      let postData = new Consumer();
      postData.id = this.consumer.id;
      postData.name = formValue.name;
      postData.undeterminatedAccess = formValue.undeterminatedAccess;

      if (!postData.undeterminatedAccess) {
        const startDate = formValue.startDate;
        const endDate = formValue.endDate;
        postData.startDate = startDate ? startDate.toISOString() : new Date().toISOString();
        postData.endDate = endDate ? endDate.toISOString() : new Date().toISOString();
      }

      postData.restService = formValue.restService;
      postData.soapService = formValue.soapService;
      postData.evidenceService = formValue.evidenceService;
      postData.requestRate = !isNaN(formValue.requestRate) ? formValue.requestRate : 0;
      postData.mbRate = !isNaN(formValue.mbRate) ? formValue.mbRate : 0;
      postData.timeFormat = formValue.timeFormat;
      postData.cleanLicensePlate = formValue.cleanLicensePlate;

      formValue.insurances.forEach((value) => {
        postData.insurances.push(value);
      });

      formValue.clients.forEach((value) => {
        postData.clients.push(value);
      });

      formValue.vehicles.forEach((value) => {
        postData.vehicles.push(value);
      });

      postData.responseFields = formValue.responseFields
        .map((checked, i) => checked ? this.consumerFields[i].id : null)
        .filter(v => v !== null);

      if (formValue.company) {
        postData.tenant = formValue.company['id'];
        formValue.serviceParameters.map((param) => {
          postData.serviceParameters.push({
            key: param.key,
            value: param.value
          });
        });
      }

      this.saving = true;
      if (this.isNew) {
        postData.active = true;
        this.consumersService.createConsumer(postData).subscribe((res) => {
          this.saving = false;
          const message = `Integración ${ postData.name } creada.`;
          this.appService.showSnackBar(message, SnackbarType.SUCCESS);
          this.router.navigate(['/consumers']);
        }, (err) => {
          this.saving = false;
          const message = `Error al crear la integración ${ postData.name }.`;
          this.appService.showSnackBar(message, SnackbarType.ERROR);
        });
      } else {
        this.consumersService.updateConsumer(postData).subscribe((res) => {
          this.saving = false;
          const message = `Integración ${ postData.name } actualizada.`;
          this.appService.showSnackBar(message, SnackbarType.SUCCESS);
          this.router.navigate(['/consumers']);
        }, (err) => {
          this.saving = false;
          const message = `Error al actualizar la integración ${ postData.name }.`;
          this.appService.showSnackBar(message, SnackbarType.ERROR);
        });
      }
    }
  }

  changeUndeterminatedAccess(checked: boolean) {
    if (!checked) {
      const startDate = this.consumerForm.get('startDate').value;
      const endDate = this.consumerForm.get('endDate').value;

      if (!startDate) {
        this.consumerForm.get('startDate').setValue(new Date());
      }

      if (!endDate) {
        this.consumerForm.get('endDate').setValue(new Date());
      }
    }
  }

  // AVL inputs
  // Vehicles
  initVehiclesForm() {
    if (this.consumer.vehicles) {
      this.consumer.vehicles.map(v => {
        const vehiclesArray = this.consumerForm.get('vehicles');
        const vehicleControl = new FormControl(v);
        (<FormArray> vehiclesArray).push(vehicleControl);
      });
    }
  }

  removeVehicle(vehicle: Vehicle) {
    const vehiclesFormArray = (<FormArray> this.consumerForm.get('vehicles'));
    const vehiclesArr = vehiclesFormArray.value;
    const index = vehiclesArr.findIndex(v => v.id === vehicle.id);
    vehiclesFormArray.removeAt(index);
  }

  vehiclesAutocompleteEnd(event: MatChipInputEvent): void {
    // Clear input value
    if (event.input) {
      event.input.value = '';
    }
    this.consumerForm.get('vehiclesCtrl').setValue(null);
  }

  vehicleSelected(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value) {
      const vehiclesArray = this.consumerForm.get('vehicles');
      const vehicleControl = new FormControl(event.option.value);
      (<FormArray> vehiclesArray).push(vehicleControl);
    }
    this.consumerForm.get('vehiclesCtrl').setValue(null);
    this.vehiclesAutocomplete.nativeElement.value = '';
  }

  // Clients
  initClientsForm() {
    if (this.consumer.clients) {
      this.consumer.clients.map(v => {
        const clientsArray = this.consumerForm.get('clients');
        const clientControl = new FormControl(v);
        (<FormArray> clientsArray).push(clientControl);
      });
    }
  }

  removeClient(client: Client) {
    const clientsFormArray = (<FormArray> this.consumerForm.get('clients'));
    const clientsArr = clientsFormArray.value;
    const index = clientsArr.findIndex(c => c.id === client.id);
    clientsFormArray.removeAt(index);
  }

  clientsAutocompleteEnd(event: MatChipInputEvent): void {
    // Clear input value
    if (event.input) {
      event.input.value = '';
    }
    this.consumerForm.get('clientsCtrl').setValue(null);
  }

  clientSelected(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value) {
      const clientsArray = this.consumerForm.get('clients');
      const clientControl = new FormControl(event.option.value);
      (<FormArray> clientsArray).push(clientControl);
    }
    this.consumerForm.get('clientsCtrl').setValue(null);
    this.clientsAutocomplete.nativeElement.value = '';
  }

  // Insurances
  initInsurancesForm() {
    if (this.consumer.insurances) {
      this.consumer.insurances.map(v => {
        const insurancesArray = this.consumerForm.get('insurances');
        const insuranceControl = new FormControl(v);
        (<FormArray> insurancesArray).push(insuranceControl);
      });
    }
  }

  removeInsurance(insurance: Insurance) {
    const insurancesFormArray = (<FormArray> this.consumerForm.get('insurances'));
    const insurancesArr = insurancesFormArray.value;
    const index = insurancesArr.findIndex(c => c.id === insurance.id);
    insurancesFormArray.removeAt(index);
  }

  insurancesAutocompleteEnd(event: MatChipInputEvent): void {
    // Clear input value
    if (event.input) {
      event.input.value = '';
    }
    this.consumerForm.get('insurancesCtrl').setValue(null);
  }

  insuranceSelected(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value) {
      const insurancesArray = this.consumerForm.get('insurances');
      const insuranceControl = new FormControl(event.option.value);
      (<FormArray> insurancesArray).push(insuranceControl);
    }
    this.consumerForm.get('insurancesCtrl').setValue(null);
    this.insuranceAutocomplete.nativeElement.value = '';
  }

  alreadySelected(element: any, selected: any[]) {
    return selected.find(el => el.id === element.id) ? true : false;
  }

  // Response fields
  get responseFieldsArray() {
    return this.consumerForm.controls.responseFields as FormArray;
  }

  initConsumerFields() {
    const allConsumerFields = (this.consumer.responseFields.length === 0);
    const fieldsFormArray = (<FormArray> this.consumerForm.get('responseFields'));
    this.consumerFields.forEach(field => {
      const selected = (allConsumerFields || this.isResponseFieldSelected(field)) || false;
      fieldsFormArray.push(new FormControl(selected));
    });
    this.consumerForm.get('allResponseFields').setValue(allConsumerFields);
  }

  isResponseFieldSelected(field: ConsumerField) {
    const foundField = this.consumer.responseFields.find((f) => f === field.id);
    return foundField ? true : false;
  }

  isAllFieldsSelected() {
    const values = (<[]> this.consumerForm.get('responseFields').value);
    const allValuesTrue = values.every(v => v === true);
    this.consumerForm.get('allResponseFields').setValue(allValuesTrue);
    return allValuesTrue;
  }

  isNoneFieldsSelected() {
    const values = (<[]> this.consumerForm.get('responseFields').value);
    const allValuesFalse = values.every(v => v === false);
    return allValuesFalse;
  }

  masterFieldsToggle(allSelected) {
    this.consumerForm.get('responseFields')
      .patchValue(Array(this.consumerFields.length).fill(allSelected), { emitEvent: false });
  }

  // Tenant
  initTenantForm() {
    if (this.consumer.tenant) {
      const tenant = this.companies.find(c => c.id === this.consumer.tenant);
      this.consumerForm.get('company').setValue(tenant || null);
    }
    this.tenantChange();
  }

  tenantChange() {
    const selectedCompany = this.consumerForm.get('company').value;
    (<FormArray> this.consumerForm.get('serviceParameters')).clear();
    if (selectedCompany) {
      this.setServiceParameterFields(selectedCompany);
    }
  }

  setServiceParameterFields(company: Company) {
    const serviceParamsArray = this.consumerForm.get('serviceParameters');
    const configuredParams: {
      key: string,
      value: string
    }[] = company.id === this.consumer.tenant ? this.consumer.serviceParameters : [];

    // const selectedCompany = this.consumerForm.get('company').value;
    company.serviceParameters.forEach(p => {
      const configuredParam = configuredParams.find(cp => {
        return cp.key === p.key
      });
      const paramGroup = new FormGroup({
        key: new FormControl(p.key),
        description: new FormControl(p.value),
        value: new FormControl(configuredParam ? configuredParam.value : null)
      });
      (<FormArray> serviceParamsArray).push(paramGroup);
    });
  }

  getServiceParamsControls() {
    return (<FormArray> this.consumerForm.controls.serviceParameters).controls;
  }
}
