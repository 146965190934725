<div class="auth-view"
  style="background-image: url('assets/img/login_logo.svg'), linear-gradient(to right, #404d57, #122133);">
  <div class="form-wrapper">
    <img src="assets/img/logo.png" alt="B2B" class="logo">
    <form #authForm="ngForm" (ngSubmit)="onSubmit(authForm)">
      <div>
        <mat-form-field appearance="fill">
          <mat-label>User</mat-label>
          <input
            matInput
            ngModel
            name="username"
            required
          >
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Password</mat-label>
          <input
            matInput
            type="password"
            ngModel
            name="password"
            required
          >
        </mat-form-field>
      </div>
      <div class="spinner-wrapper">
        <p class="error-message" *ngIf="!isLoading && errorMessage">
          {{ errorMessage }}
        </p>
        <mat-spinner
          *ngIf="isLoading"
          [diameter]="25"
        ></mat-spinner>
      </div>
      <div class="button-wrapper">
        <button
          mat-flat-button
          color="primary"
          type="submit"
          [disabled]="!authForm.valid"
        >
          Ingresar
        </button>
      </div>
    </form>
  </div>
</div>
