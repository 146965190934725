<div class="box">
  <app-load-indicator [show]="loading || saving"></app-load-indicator>
  <a [routerLink]="['/leasing-companies']" class="back-link">
    <span><mat-icon class="back-icon">arrow_back</mat-icon> Arrendadoras</span>
  </a>
  <div *ngIf="!loading">
    <div class="load-error page-header" *ngIf="loadError">
      <h1 class="title" color="primary">{{ loadError }}</h1>
    </div>
    <div class="leasing-company-view" *ngIf="!loadError">
      <div class="page-header">
        <h1 class="title">{{ title | uppercase }}</h1>
      </div>
      <div>
        <form class="leasing-company-form"
          [formGroup]="companyForm"
          (ngSubmit)="onSubmit()">

          <section>
            <div class="section-title">
              <h3>Datos generales</h3>
            </div>
            <div class="section-body grid">
              <div class="col">
                <mat-form-field class="full-width-input">
                  <mat-label>Nombre</mat-label>
                  <input
                    type="text"
                    matInput
                    formControlName="name"
                    required>
                </mat-form-field>
              </div>
              <div class="col">
                <p>API key id: <span>{{ leasingCompany.apiKeyId }}</span></p>
                <p>Activo: <span>{{ leasingCompany.active ? 'Si' : 'No' }}</span></p>
                <p>Fecha de creación: <span>{{ leasingCompany.created | date: 'medium' }}</span></p>
                <p>Última modificación: <span>{{ leasingCompany.lastModified | date: 'medium' }}</span></p>
              </div>
            </div>
          </section>

          <section>
            <div class="section-title">
              <h3>Unidades asignadas</h3>
            </div>
            <div class="section-body box">
              <!-- Assigned insurances -->
              <div>
                <mat-form-field class="chip-list-input">
                  <mat-chip-list #assignedInsurancesList aria-label="Insurance selection">
                    <mat-chip
                      *ngFor="let insurance of companyForm.value.insurances"
                      (removed)="removeInsurance(insurance)">
                      {{ insurance.nombre }}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input
                      placeholder="Coberturas asignadas"
                      #insuranceInput
                      formControlName="insurancesCtrl"
                      [matChipInputFor]="assignedInsurancesList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      [matAutocomplete]="insurancesAutocomplete"
                      (matChipInputTokenEnd)="insurancesAutocompleteEnd($event)">
                  </mat-chip-list>
                </mat-form-field>
                <mat-autocomplete
                  autoActiveFirstOption
                  #insurancesAutocomplete="matAutocomplete"
                  (optionSelected)="insuranceSelected($event)">
                  <mat-option
                    *ngIf="fetchingAutocompleteData"
                    class="is-loading">
                    Cargando coberturas...
                  </mat-option>
                  <ng-container *ngIf="!fetchingAutocompleteData">
                    <mat-option
                      *ngFor="let insurance of filteredInsurances"
                      [value]="insurance"
                      [disabled]="alreadySelected(insurance, companyForm.value.insurances)">
                      {{ insurance.nombre }}
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
              </div>

              <!-- Assigned clients -->
              <div>
                <mat-form-field class="chip-list-input">
                  <mat-chip-list #assignedClientsList aria-label="Client selection">
                    <mat-chip
                      *ngFor="let client of companyForm.value.clients"
                      (removed)="removeClient(client)">
                      {{ client.nombre }}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input
                      placeholder="Clientes asignados"
                      #clientsInput
                      formControlName="clientsCtrl"
                      [matChipInputFor]="assignedClientsList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      [matAutocomplete]="clientsAutocomplete"
                      (matChipInputTokenEnd)="clientsAutocompleteEnd($event)">
                  </mat-chip-list>
                </mat-form-field>
                <mat-autocomplete
                  autoActiveFirstOption
                  #clientsAutocomplete="matAutocomplete"
                  (optionSelected)="clientSelected($event)">
                  <mat-option
                    *ngIf="fetchingAutocompleteData"
                    class="is-loading">
                    Cargando clients...
                  </mat-option>
                  <ng-container *ngIf="!fetchingAutocompleteData">
                    <mat-option
                      *ngFor="let client of filteredClients"
                      [value]="client"
                      [disabled]="alreadySelected(client, companyForm.value.clients)">
                      {{ client.nombre }}
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
              </div>

              <!-- Assigned vehicles -->
              <div>
                <mat-form-field class="chip-list-input">
                  <mat-chip-list #assignedVehiclesList aria-label="Vehicle selection">
                    <mat-chip
                      *ngFor="let vehicle of companyForm.value.vehicles"
                      (removed)="removeVehicle(vehicle)">
                      {{ vehicle.text }}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input
                      placeholder="Vehículos asignados"
                      #vehiclesInput
                      formControlName="vehiclesCtrl"
                      [matChipInputFor]="assignedVehiclesList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      [matAutocomplete]="vehiclesAutocomplete"
                      (matChipInputTokenEnd)="vehiclesAutocompleteEnd($event)">
                  </mat-chip-list>
                </mat-form-field>
                <mat-autocomplete
                  autoActiveFirstOption
                  #vehiclesAutocomplete="matAutocomplete"
                  (optionSelected)="vehicleSelected($event)">
                  <mat-option
                    *ngIf="fetchingAutocompleteData"
                    class="is-loading">
                    Cargando vehículos...
                  </mat-option>
                  <ng-container *ngIf="!fetchingAutocompleteData">
                    <mat-option
                      *ngFor="let vehicle of filteredVehicles"
                      [value]="vehicle"
                      [disabled]="alreadySelected(vehicle, companyForm.value.vehicles)">
                      {{ vehicle.text }}
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
              </div>
            </div>
          </section>
          <section>
            <button
              mat-flat-button
              color="primary"
              type="submit"
              [disabled]="!companyForm.valid">
              Guardar
            </button>
            <a
              mat-flat-button
              color="basic"
              [routerLink]="['/leasing-companies']">
              Cancelar
            </a>
          </section>
        </form>
      </div>
    </div>
  </div>
</div>
