<div class="box">
  <div class="leasin-companies-list">
    <h2>Arrendadoras</h2>
    <div class="tools">
      <div class="search-box">
        <mat-form-field>
          <input matInput
            placeholder="Buscar"
            (keyup)="searchKeyUp($event)">
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div class="action-buttons">
        <button mat-icon-button color="primary" (click)="loadLeasingCompanies()">
          <mat-icon>autorenew</mat-icon>
        </button>
        <a mat-flat-button color="primary" [routerLink]="['/leasing-companies', '0']">
          <mat-icon>add</mat-icon> Crear arrendadora
        </a>
      </div>
    </div>

    <div class="table-container">
      <app-load-indicator [show]="loadingData"></app-load-indicator>

      <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="apiKey">
          <mat-header-cell *matHeaderCellDef mat-sort-header>API Key</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.apiKeyId }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="active">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Activo</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.active ? 'Si' : 'No' }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="lastModified">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Última modificación</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.lastModified }} </mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="actions" class="action-column">
          <mat-header-cell *matHeaderCellDef class="action-column"></mat-header-cell>
          <mat-cell *matCellDef="let element" class="action-column">
            <button mat-icon-button
              color="warn"
              type="button"
              class="action-button"
              (click)="confirmDelete($event, element)">
              <mat-icon color="warn">delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
        <mat-row class="element-row"
          *matRowDef="let element; columns: displayedColumns;"
          [routerLink]="['/leasing-companies', element.id]">
        </mat-row>
      </mat-table>
    </div>

    <mat-paginator [length]="leasingCompanies.length" [pageSize]="50"></mat-paginator>
  </div>
</div>
