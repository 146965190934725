<div class="box">
  <app-load-indicator [show]="loading || saving"></app-load-indicator>
  <a [routerLink]="['/consumers']" class="back-link">
    <span><mat-icon class="back-icon">arrow_back</mat-icon> Integraciones</span>
  </a>
  <div *ngIf="!loading">
    <div class="load-error page-header" *ngIf="loadError">
      <h1 class="title" color="primary">{{ loadError }}</h1>
    </div>
    <div class="consumer-view" *ngIf="!loadError">
      <div class="page-header">
        <h1 class="title">{{ title | uppercase }}</h1>
      </div>
      <div>
        <form class="consumer-form"
          [formGroup]="consumerForm"
          (ngSubmit)="onSubmit()">

          <section>
            <div class="section-title">
              <h3>Datos generales</h3>
            </div>
            <div class="section-body grid">
              <div class="col">
                <mat-form-field class="full-width-input">
                  <mat-label>Nombre</mat-label>
                  <input
                    type="text"
                    matInput
                    formControlName="name"
                    required>
                </mat-form-field>
                <section class="form-sub-section">
                  <h4>Período de integración</h4>
                  <mat-checkbox
                    color="primary"
                    formControlName="undeterminatedAccess"
                    (change)="changeUndeterminatedAccess($event.checked)">
                    Acceso indeterminado
                  </mat-checkbox>
                  <br>
                  <ng-container *ngIf="!consumerForm.value.undeterminatedAccess">
                    <div class="inline-form-fields">
                      <mat-form-field>
                        <mat-label>Fecha inicio</mat-label>
                        <input matInput
                          [matDatepicker]="startDate"
                          formControlName="startDate">
                        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                        <mat-datepicker #startDate></mat-datepicker>
                      </mat-form-field>
                      <span class="separator"></span>
                      <mat-form-field>
                        <mat-label>Fecha fin</mat-label>
                        <input matInput
                          [matDatepicker]="endDate"
                          formControlName="endDate">
                        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                        <mat-datepicker #endDate></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </ng-container>
                </section>
                <section class="form-sub-section">
                  <h4>Servicios Web</h4>
                  <div>
                    <div>
                      <mat-checkbox formControlName="restService" color="primary">
                        Servicio REST
                      </mat-checkbox>
                      <div class="inline-form-fields rest-rates"  *ngIf="consumerForm.value.restService">
                        <mat-form-field>
                          <mat-label>Max. peticiones por hora</mat-label>
                          <input
                            type="number"
                            matInput
                            formControlName="requestRate" >
                        </mat-form-field>
                        <div *ngIf="consumerForm.controls.requestRate.errors?.pattern">
                          User name not valid.
                        </div>
                        <span class="separator"></span>
                        <mat-form-field>
                          <mat-label>Mb/mes</mat-label>
                          <input
                            type="number"
                            matInput
                            formControlName="mbRate">
                        </mat-form-field>
                      </div>
                    </div>
                    <div>
                      <mat-checkbox formControlName="soapService" color="primary">
                        Servicio SOAP
                      </mat-checkbox>
                    </div>
                    <div>
                      <mat-checkbox formControlName="evidenceService" color="primary">
                        Evidencias ERE
                      </mat-checkbox>
                    </div>
                  </div>
                </section>
                <section class="form-sub-section">
                  <h4>Otros</h4>
                  <div class="inline-form-fields">
                    <mat-form-field>
                      <mat-label>Formato de hora</mat-label>
                      <mat-select formControlName="timeFormat">
                        <mat-option *ngFor="let format of timeFormats" [value]="format.value">
                          {{ format.option }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <br>
                  <mat-checkbox formControlName="cleanLicensePlate" color="primary">
                    Limpiar placa <i>(quita espacios y guiones)</i>
                  </mat-checkbox>
                </section>
              </div>
              <div class="col">
                <p>Código de integrador: <span>{{ consumer.uuidCode }}</span></p>
                <p>Fecha de creación: <span>{{ consumer.created | date: 'medium' }}</span></p>
                <p>Última modificación: <span>{{ consumer.lastModified | date: 'medium' }}</span></p>
                <p *ngIf="consumer.apiKeys.length">{{ consumer.apiKeys[0].description }} <i>(API Key)</i>: <span>{{ consumer.apiKeys[0].secretKey }}</span></p>

                <section class="form-sub-section rate-report">
                  <h4>Reporte de consumo</h4>
                  <p>Day rate: <span>{{ consumer.dayRate }}</span></p>
                  <p>Month rate: <span>{{ consumer.monthRate }}</span></p>
                  <p>Three months rate: <span>{{ consumer.threeMonthsRate }}</span></p>
                  <p>Month consume: <span>{{ consumer.monthConsume }}</span></p>
                  <p>Three months consume: <span>{{ consumer.threeMonthsConsume }}</span></p>
                </section>
              </div>
            </div>
          </section>

          <section>
            <div class="section-title">
              <h3>Unidades asignadas</h3>
            </div>
            <div class="section-body box">
              <!-- Assigned insurances -->
              <div>
                <mat-form-field class="chip-list-input">
                  <mat-chip-list #assignedInsurancesList aria-label="Insurance selection">
                    <mat-chip
                      *ngFor="let insurance of consumerForm.value.insurances"
                      (removed)="removeInsurance(insurance)">
                      {{ insurance.nombre }}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input
                      placeholder="Coberturas asignadas"
                      #insuranceInput
                      formControlName="insurancesCtrl"
                      [matChipInputFor]="assignedInsurancesList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      [matAutocomplete]="insurancesAutocomplete"
                      (matChipInputTokenEnd)="insurancesAutocompleteEnd($event)">
                  </mat-chip-list>
                </mat-form-field>
                <mat-autocomplete
                  autoActiveFirstOption
                  #insurancesAutocomplete="matAutocomplete"
                  (optionSelected)="insuranceSelected($event)">
                  <mat-option
                    *ngIf="fetchingAutocompleteData"
                    class="is-loading">
                    Cargando coberturas...
                  </mat-option>
                  <ng-container *ngIf="!fetchingAutocompleteData">
                    <mat-option
                      *ngFor="let insurance of filteredInsurances"
                      [value]="insurance"
                      [disabled]="alreadySelected(insurance, consumerForm.value.insurances)">
                      {{ insurance.nombre }}
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
              </div>

              <!-- Assigned clients -->
              <div>
                <mat-form-field class="chip-list-input">
                  <mat-chip-list #assignedClientsList aria-label="Client selection">
                    <mat-chip
                      *ngFor="let client of consumerForm.value.clients"
                      (removed)="removeClient(client)">
                      {{ client.nombre }}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input
                      placeholder="Clientes asignados"
                      #clientsInput
                      formControlName="clientsCtrl"
                      [matChipInputFor]="assignedClientsList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      [matAutocomplete]="clientsAutocomplete"
                      (matChipInputTokenEnd)="clientsAutocompleteEnd($event)">
                  </mat-chip-list>
                </mat-form-field>
                <mat-autocomplete
                  autoActiveFirstOption
                  #clientsAutocomplete="matAutocomplete"
                  (optionSelected)="clientSelected($event)">
                  <mat-option
                    *ngIf="fetchingAutocompleteData"
                    class="is-loading">
                    Cargando clients...
                  </mat-option>
                  <ng-container *ngIf="!fetchingAutocompleteData">
                    <mat-option
                      *ngFor="let client of filteredClients"
                      [value]="client"
                      [disabled]="alreadySelected(client, consumerForm.value.clients)">
                      {{ client.nombre }}
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
              </div>

              <!-- Assigned vehicles -->
              <div>
                <mat-form-field class="chip-list-input">
                  <mat-chip-list #assignedVehiclesList aria-label="Vehicle selection">
                    <mat-chip
                      *ngFor="let vehicle of consumerForm.value.vehicles"
                      (removed)="removeVehicle(vehicle)">
                      {{ vehicle.text }}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input
                      placeholder="Vehículos asignados"
                      #vehiclesInput
                      formControlName="vehiclesCtrl"
                      [matChipInputFor]="assignedVehiclesList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      [matAutocomplete]="vehiclesAutocomplete"
                      (matChipInputTokenEnd)="vehiclesAutocompleteEnd($event)">
                  </mat-chip-list>
                </mat-form-field>
                <mat-autocomplete
                  autoActiveFirstOption
                  #vehiclesAutocomplete="matAutocomplete"
                  (optionSelected)="vehicleSelected($event)">
                  <mat-option
                    *ngIf="fetchingAutocompleteData"
                    class="is-loading">
                    Cargando vehículos...
                  </mat-option>
                  <ng-container *ngIf="!fetchingAutocompleteData">
                    <mat-option
                      *ngFor="let vehicle of filteredVehicles"
                      [value]="vehicle"
                      [disabled]="alreadySelected(vehicle, consumerForm.value.vehicles)">
                      {{ vehicle.text }}
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
              </div>

              <div>
                <h4>Campos a despegar</h4>

                <div>
                  <span>
                    <mat-checkbox
                      color="primary"
                      (change)="$event ? masterFieldsToggle($event.checked) : null"
                      [checked]="isAllFieldsSelected()"
                      formControlName="allResponseFields">
                      Todos
                    </mat-checkbox>
                  </span>

                  <span>
                    <ul class="fields-list">
                      <li *ngFor="let field of responseFieldsArray.controls; let i=index">
                        <mat-checkbox
                          color="primary"
                          [formControl]="field">
                          {{ consumerFields[i].description }}
                        </mat-checkbox>
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div class="section-title">
              <h3>Stream Kafka</h3>
            </div>
            <div class="section-body box">
              <mat-form-field class="company-select">
                <mat-label>Compañía</mat-label>
                <mat-select formControlName="company" (selectionChange)="tenantChange()">
                  <mat-option>---</mat-option>
                  <mat-option *ngFor="let company of companies" [value]="company">
                    {{ company.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="service-parameters-container"
                formArrayName="serviceParameters"
                *ngIf="consumerForm.get('company').value">

                <h4>Parámetros de servicio <b>{{ consumerForm.get('company').value.name }}</b></h4>

                <div *ngFor="let serviceParamControl of getServiceParamsControls(); let i = index;">
                  <div [formGroupName]="i">
                    <mat-form-field>
                      <mat-label>{{ serviceParamControl.get('description').value }}</mat-label>
                      <input
                        type="text"
                        matInput
                        formControlName="value">
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <button
              mat-flat-button
              color="primary"
              type="submit"
              [disabled]="!consumerForm.valid">
              Guardar
            </button>
            <a
              mat-flat-button
              color="basic"
              [routerLink]="['/consumers']">
              Cancelar
            </a>
          </section>
        </form>
      </div>
    </div>
  </div>
</div>
