import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  errorMessage: string = null;
  isLoading: boolean = false;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
  }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    this.isLoading = true;
    this.errorMessage = null;
    const username = form.value.username;
    const password = form.value.password;
    this.authService.login(username, password).subscribe(res => {
      this.isLoading = false;
      this.router.navigate(['/']);
    }, errorMessage => {
      this.errorMessage = errorMessage;
      this.isLoading = false;
    });
  }
}
