import { ClientResponse } from './client-response.model';

export class Client {
  id: number;
  nombre: string;

  deserializeClientResponse(res: ClientResponse) {
    this.id = res.id;
    this.nombre = res.text;
  }
}