import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ConsumersService } from 'src/app/consumers/consumers.service';
import { Client } from 'src/app/shared/models/client.model';
import { Insurance } from 'src/app/shared/models/insurance.model';
import { LeasingCompany } from 'src/app/shared/models/leasing-company.model';
import { Vehicle } from 'src/app/shared/models/vehicle.model';
import { SnackbarType } from 'src/app/snackbar/snackbar.component';
import { LeasingCompaniesService } from '../leasing-companies.service';

@Component({
  selector: 'app-leasing-company-view',
  templateUrl: './leasing-company-view.component.html',
  styleUrls: ['./leasing-company-view.component.scss']
})
export class LeasingCompanyViewComponent implements OnInit {
  @ViewChild('clientsInput') clientsAutocomplete: ElementRef;
  @ViewChild('insuranceInput') insuranceAutocomplete: ElementRef;
  @ViewChild('vehiclesInput') vehiclesAutocomplete: ElementRef;
  companyForm: FormGroup;
  paramsSub: Subscription;
  fetchCompanySub: Subscription;
  leasingCompany: LeasingCompany;
  loadError: string;
  loading: boolean;
  saving: boolean;
  isNew: boolean = true;
  title: string = 'Crear arrendadora';
  separatorKeysCodes: number[] = [ENTER, COMMA];

  // API Data
  fetchingAutocompleteData: boolean = false;
  filteredVehicles: Vehicle[] = [];
  filteredClients: Client[] = [];
  filteredInsurances: Insurance[] = [];
  timeFormats: { value: string, option: string }[] = [];

  onlyNumbersRegex = '^[0-9]*$';
  

  constructor(
    private leasingCompanyService: LeasingCompaniesService,
    private consumersService: ConsumersService,
    private appService: AppService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.paramsSub = this.route.params.subscribe(
      (params: Params) => {
        this.loading = true;
        // Init new or edit consumer
        const consumerId = params['id'];
        if (consumerId !== '0') {
          this.initEditLeasingCompany(consumerId);
        } else {
          this.initNewLeasingCompany();
        }
      }
    );
  }

  ngOnDestroy() {
    this.paramsSub.unsubscribe();
  }

  initEditLeasingCompany(consumerId: string) {
    this.isNew = false;
    this.title = 'Editar arrendadora';
    this.fetchCompanySub = this.leasingCompanyService.getLeasingCompany(consumerId)
      .subscribe((leasingCompany: LeasingCompany) => {
        this.loading = false;
        this.leasingCompany = leasingCompany;
        this.initForm();
      }, (err) => {
        if (err.status === 404) {
          this.router.navigate(['/404']);
        }
        this.loading = false;
        this.loadError = 'Error al cargar los datos';
      });
  }

  initNewLeasingCompany() {
    this.leasingCompany = new LeasingCompany();
    this.initForm();
    this.loading = false;
  }

  initForm() {
    this.companyForm = new FormGroup({
      name: new FormControl(this.leasingCompany.name, Validators.required),
      vehicles: new FormArray([]),
      vehiclesCtrl: new FormControl(),
      clients: new FormArray([]),
      clientsCtrl: new FormControl(),
      insurances: new FormArray([]),
      insurancesCtrl: new FormControl(),
    });

    // Init AVL autocompletes
    this.companyForm.get('vehiclesCtrl').valueChanges.pipe(
      debounceTime(250),
      tap(() => {
        this.fetchingAutocompleteData = true;
      }),
      switchMap(value => {
        if (!value) {
          this.fetchingAutocompleteData = false;
          return of({ items: [] });
        }
        return this.consumersService.fetchVehiclesByTerm(value);
      })
    ).subscribe((data: { more: boolean, items: [] }) => {
      this.fetchingAutocompleteData = false;
      this.filteredVehicles = data ? data.items : [];
    }, err => null);

    this.companyForm.get('clientsCtrl').valueChanges.pipe(
      debounceTime(250),
      tap(() => {
        this.fetchingAutocompleteData = true;
      }),
      switchMap(value => {
        if (!value) {
          this.fetchingAutocompleteData = false;
          return of({ items: [] });
        }
        return this.consumersService.fetchClientsByTerm(value)
      })
    ).subscribe((data: { more: boolean, items: [] }) => {
      this.fetchingAutocompleteData = false;
      this.filteredClients = data ? data.items : [];
    }, err => null);

    this.companyForm.get('insurancesCtrl').valueChanges.pipe(
      debounceTime(250),
      tap(() => {
        this.fetchingAutocompleteData = true;
      }),
      switchMap(value => {
        if (!value) {
          this.fetchingAutocompleteData = false;
          return of({ items: [] });
        }
        return this.consumersService.fetchInsurancesByTerm(value);
      })
    ).subscribe((data: { more: boolean, items: [] }) => {
      this.fetchingAutocompleteData = false;
      this.filteredInsurances = data ? data.items : [];
    }, err => null);

    this.initVehiclesForm();
    this.initClientsForm();
    this.initInsurancesForm();
  }

  onSubmit() {
    if (this.companyForm.valid) {
      const formValue = this.companyForm.value;
      let postData = new LeasingCompany();
      postData.id = this.leasingCompany.id;
      postData.name = formValue.name;

      const selectedInsurances = [];
      formValue.insurances.forEach((value) => {
        selectedInsurances.push(value.id);
      });

      const selectedClients = [];
      formValue.clients.forEach((value) => {
        selectedClients.push(value.id);
      });

      const selectedVehicles = [];
      formValue.vehicles.forEach((value) => {
        selectedVehicles.push(value.id);
      });

      postData.plugin = {
        idTipoCobertura: selectedInsurances,
        idCliente: selectedClients,
        idVehiculo: selectedVehicles,
      };

      this.saving = true;
      if (this.isNew) {
        this.leasingCompanyService.createLeasingCompany(postData).subscribe((res) => {
          this.saving = false;
          const message = `Arrendadora ${ this.leasingCompany.name } creada.`;
          this.appService.showSnackBar(message, SnackbarType.SUCCESS);
          this.router.navigate(['/leasing-companies']);
        }, (err) => {
          this.saving = false;
          const message = `Error al crear la arrendadora ${ this.leasingCompany.name }.`;
          this.appService.showSnackBar(message, SnackbarType.ERROR);
        });
      } else {
        this.leasingCompanyService.updateLeasingCompany(postData).subscribe((res) => {
          this.saving = false;
          const message = `Arrendadora ${ this.leasingCompany.name } actualizada.`;
          this.appService.showSnackBar(message, SnackbarType.SUCCESS);
          this.router.navigate(['/leasing-companies']);
        }, (err) => {
          this.saving = false;
          const message = `Error al actualizar la arrendadora ${ this.leasingCompany.name }.`;
          this.appService.showSnackBar(message, SnackbarType.ERROR);
        });
      }
    }
  }

  // AVL inputs
  // Vehicles
  initVehiclesForm() {
    if (this.leasingCompany.vehiculos.length) {
      this.leasingCompany.vehiculos.map(v => {
        const vehiclesArray = this.companyForm.get('vehicles');
        const vehicleControl = new FormControl(v);
        (<FormArray> vehiclesArray).push(vehicleControl);
      });
    }
  }

  removeVehicle(vehicle: Vehicle) {
    const vehiclesFormArray = (<FormArray> this.companyForm.get('vehicles'));
    const vehiclesArr = vehiclesFormArray.value;
    const index = vehiclesArr.findIndex(v => v.id === vehicle.id);
    vehiclesFormArray.removeAt(index);
  }

  vehiclesAutocompleteEnd(event: MatChipInputEvent): void {
    // Clear input value
    if (event.input) {
      event.input.value = '';
    }
    this.companyForm.get('vehiclesCtrl').setValue(null);
  }

  vehicleSelected(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value) {
      const vehiclesArray = this.companyForm.get('vehicles');
      const vehicleControl = new FormControl(event.option.value);
      (<FormArray> vehiclesArray).push(vehicleControl);
    }
    this.companyForm.get('vehiclesCtrl').setValue(null);
    this.vehiclesAutocomplete.nativeElement.value = '';
  }

  // Clients
  initClientsForm() {
    if (this.leasingCompany.clientes.length) {
      this.leasingCompany.clientes.map(v => {
        const clientsArray = this.companyForm.get('clients');
        const clientControl = new FormControl(v);
        (<FormArray> clientsArray).push(clientControl);
      });
    }
  }

  removeClient(client: Client) {
    const clientsFormArray = (<FormArray> this.companyForm.get('clients'));
    const clientsArr = clientsFormArray.value;
    const index = clientsArr.findIndex(c => c.id === client.id);
    clientsFormArray.removeAt(index);
  }

  clientsAutocompleteEnd(event: MatChipInputEvent): void {
    // Clear input value
    if (event.input) {
      event.input.value = '';
    }
    this.companyForm.get('clientsCtrl').setValue(null);
  }

  clientSelected(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value) {
      const clientsArray = this.companyForm.get('clients');
      const clientControl = new FormControl(event.option.value);
      (<FormArray> clientsArray).push(clientControl);
    }
    this.companyForm.get('clientsCtrl').setValue(null);
    this.clientsAutocomplete.nativeElement.value = '';
  }

  // Insurances
  initInsurancesForm() {
    if (this.leasingCompany.coberturas.length) {
      this.leasingCompany.coberturas.map(v => {
        const insurancesArray = this.companyForm.get('insurances');
        const insuranceControl = new FormControl(v);
        (<FormArray> insurancesArray).push(insuranceControl);
      });
    }
  }

  removeInsurance(insurance: Insurance) {
    const insurancesFormArray = (<FormArray> this.companyForm.get('insurances'));
    const insurancesArr = insurancesFormArray.value;
    const index = insurancesArr.findIndex(c => c.id === insurance.id);
    insurancesFormArray.removeAt(index);
  }

  insurancesAutocompleteEnd(event: MatChipInputEvent): void {
    // Clear input value
    if (event.input) {
      event.input.value = '';
    }
    this.companyForm.get('insurancesCtrl').setValue(null);
  }

  insuranceSelected(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value) {
      const insurancesArray = this.companyForm.get('insurances');
      const insuranceControl = new FormControl(event.option.value);
      (<FormArray> insurancesArray).push(insuranceControl);
    }
    this.companyForm.get('insurancesCtrl').setValue(null);
    this.insuranceAutocomplete.nativeElement.value = '';
  }

  alreadySelected(element: any, selected: any[]) {
    return selected.find(el => el.id === element.id) ? true : false;
  }
}
