import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Consumer } from '../shared/models/consumer.model';
import { ConsumersService } from './consumers.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AppService } from '../app.service';
import { SnackbarType } from '../snackbar/snackbar.component';

@Component({
  selector: 'app-consumers',
  templateUrl: './consumers.component.html',
  styleUrls: ['./consumers.component.scss']
})
export class ConsumersComponent implements OnInit {
  @ViewChild('input', {static: false}) input : ElementRef<HTMLInputElement>
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<Consumer>;
  consumers: Consumer[] = [];
  displayedColumns: string[] = ['name', 'vehiclesUniverse', 'restService', 'soapService', 'company', 'actions'];
  searchValue: string;
  loadingData: boolean;

  constructor(
    private consumersService: ConsumersService,
    private appService: AppService,
    public confirmationDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadConsumers();
  }

  loadConsumers() {
    this.loadingData = true;
    this.consumersService.getConsumers().subscribe(consumers => {
      this.loadingData = false;
      this.consumers = consumers;
      this.dataSource = new MatTableDataSource(this.consumers);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.input.nativeElement.value = '';
    }, err => {
      this.loadingData = false;
    });
  }

  confirmDelete(evt, consumer: Consumer) {
    evt.stopPropagation();
    const dialogRef = this.confirmationDialog.open(ConfirmationDialogComponent, {
      data: `¿Desea eliminar la integración ${ consumer.name }?`
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.deleteConsumer(consumer);
      }
    });
  }

  downloadFile(evt, consumer: Consumer) {
    evt.stopPropagation();
    this.loadingData = true;
    this.consumersService.getConsumerReport(consumer.id).subscribe((data) => {
      this.loadingData = false;
      window.open(data.url, '_blank');
    }, (err) => {
      this.loadingData = false;
      const message = `Error al solicitar el reporte ${ consumer.name }.`;
      this.appService.showSnackBar(message, SnackbarType.ERROR);
    });
  }

  deleteConsumer(consumer: Consumer) {
    this.loadingData = true;
    this.consumersService.deleteConsumer(consumer.id).subscribe((res) => {
      this.loadingData = false;
      const consumerName = consumer.name;
      const index = this.dataSource.data.indexOf(consumer);
      this.dataSource.data.splice(index, 1);
      this.dataSource._updateChangeSubscription();
      const message = `La integración ${ consumerName } ha sido eliminada.`;
      this.appService.showSnackBar(message, SnackbarType.SUCCESS);
      this.input.nativeElement.value = '';
    }, (err) => {
      this.loadingData = false;
      const message = `Error al eliminar la integración ${ consumer.name }.`;
      this.appService.showSnackBar(message, SnackbarType.ERROR);
    });
  }

  searchKeyUp(event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  handleStatus(evt, consumer: Consumer) {
    evt.stopPropagation();
    const dialogRef = this.confirmationDialog.open(ConfirmationDialogComponent, {
      data: `¿Desea ${consumer?.active ? 'desactivar' : 'activar'} la integración ${ consumer.name }?`
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.loadingData = true;
        this.consumersService.updateStatus(consumer).subscribe((res) => {
          this.loadingData = false;
          const message = `El consumidor ${ consumer.name } ha sido ${res?.active ? 'activado': 'desactivado'} correctamente.`;
          this.loadConsumers();
          this.appService.showSnackBar(message, SnackbarType.SUCCESS);
        }, (err) => {
          this.loadingData = false;
          const message = `No se pudo cambiar el estatus del consumidor ${ consumer.name }.`;
          this.appService.showSnackBar(message, SnackbarType.ERROR);
        })
      }
    });
  }
}
