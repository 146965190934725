import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Consumer } from '../shared/models/consumer.model';
import { environment } from 'src/environments/environment';
import { ConsumerResponse } from '../shared/models/consumer-response.model';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ConsumersService {
  constructor(private http: HttpClient) {}

  getConsumers() {
    return this.http.get<Consumer[]>(`${ environment.apiUrl }/admin/consumers/`).pipe(
      map((res: any[]) => {
        const consumers: Consumer[] = [];
        if (res) {
          res.map(c => consumers.push(new Consumer().deserializeConsumerResponse(c)));
        }
        return consumers;
      })
    );
  }

  getConsumer(id: string) {
    return this.http.get(`${ environment.apiUrl }/admin/consumers/${ id }/`).pipe(
      map((res: ConsumerResponse) => {
        return new Consumer().deserializeConsumerResponse(res)
      })
    );
  }

  getConsumerReport(id: string) {
    return this.http.get<{url: string}>(`${ environment.apiUrl }/admin/consumers/${ id }/report`);
  }

  createConsumer(consumer: Consumer) {
    return this.http.post(`${ environment.apiUrl }/admin/consumers/create/`, consumer.serializeConsumer());
  }

  updateConsumer(consumer: Consumer) {
    return this.http.patch(`${ environment.apiUrl }/admin/consumers/${ consumer.id }/`, consumer.serializeConsumer());
  }

  deleteConsumer(consumerId: string) {
    return this.http.delete(`${ environment.apiUrl }/admin/consumers/${ consumerId }/`);
  }

  updateStatus(consumer: Consumer) {
    return this.http.put<ConsumerResponse>(`${ environment.apiUrl }/admin/consumers/${ consumer.id }/status`, consumer.serializeConsumer());
  }

  getTimeFormatOptions() {
    return this.http.get<{ value: string, option: string }[]>(`${ environment.apiUrl }/admin/date_format_options/`);
  }

  fetchVehiclesByTerm(term: string) {
    let params = new HttpParams();
    params = params.append('tokenSession', environment.avlToken);
    params = params.append('summary', '1');
    if (term) {
      params = params.append('query', term);
    }
    return this.http.get(`${ environment.avlApiUrl }/vehiculos/`, { params });
  }

  fetchClientsByTerm(term: string) {
    let params = new HttpParams();
    params = params.append('tokenSession', environment.avlToken);
    if (term) {
      params = params.append('query', term);
    }
    return this.http.get(`${ environment.avlApiUrl }/clientes/`, { params });
  }
  
  fetchInsurancesByTerm(term: string) {
    let params = new HttpParams();
    params = params.append('tokenSession', environment.avlToken);
    if (term) {
      params = params.append('query', term);
    }
    return this.http.get(`${ environment.avlApiUrl }/coberturas/`, { params });
  }
}
