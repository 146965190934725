import { Component, OnInit, OnDestroy } from '@angular/core';
import { CompaniesService } from '../companies.service';
import { Company } from 'src/app/shared/models/company.model';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgForm, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { SnackbarType } from 'src/app/snackbar/snackbar.component';

@Component({
  selector: 'app-company-view',
  templateUrl: './company-view.component.html',
  styleUrls: ['./company-view.component.scss']
})
export class CompanyViewComponent implements OnInit, OnDestroy {
  companyForm: FormGroup;
  paramsSub: Subscription;
  fetchCompanySub: Subscription;
  company: Company;
  loadError: string;
  loading: boolean;
  saving: boolean;
  isNew: boolean = true;
  title: string = 'Crear compañía';
  // urlPattern: string = '/^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/';

  constructor(
    private companiesService: CompaniesService,
    private appService: AppService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.paramsSub = this.route.params.subscribe(
      (params: Params) => {
        const companyId = params['id'];
        if (companyId !== '0') {
          this.isNew = false;
          this.loading = true;
          this.title = 'Editar compañía';
          this.fetchCompanySub = this.companiesService.getCompany(companyId).subscribe((company: Company) => {
            this.loading = false;
            this.company = company;
            this.initForm();
          }, (err) => {
            if (err.status === 404) {
              this.router.navigate(['/404']);
            }
            this.loading = false;
            this.loadError = 'Error al cargar los datos';
          });
        } else {
          this.company = new Company();
          this.initForm();
        }
      }
    );
  }

  initForm() {
    this.companyForm = new FormGroup({
      name: new FormControl(this.company.name, Validators.required),
      serviceUrl: new FormControl(this.company.serviceUrl),
      kafkaTopic: new FormControl(this.company.kafkaTopic),
      kafkaServers: new FormArray([]),
      serviceParameters: new FormArray([])
    });
    this.initKafkaServersForm();
    this.initServiceParametersForm();
  }

  initKafkaServersForm() {
    if (this.company.kafkaServers) {
      this.company.kafkaServers.map(s => this.addServer(s));
    }
  }

  initServiceParametersForm() {
    if (this.company.serviceParameters) {
      this.company.serviceParameters.map(s => this.addServiceParameter(s));
    }
  }

  ngOnDestroy() {
    this.paramsSub.unsubscribe();
    if (this.fetchCompanySub) {
      this.fetchCompanySub.unsubscribe();
    }
  }

  onSubmit() {
    if (this.companyForm.valid) {
      this.company.name = this.companyForm.get('name').value;
      this.company.serviceUrl = this.companyForm.get('serviceUrl').value;
      this.company.kafkaTopic = this.companyForm.get('kafkaTopic').value;
      this.company.kafkaServers = [];
      this.company.serviceParameters = [];

      for (let control of (<FormArray> this.companyForm.get('kafkaServers')).controls) {
        this.company.kafkaServers.push(control.value);
      }

      for (let control of (<FormArray> this.companyForm.get('serviceParameters')).controls) {
        const serviceParam = {
          key: control.get('key').value,
          value: control.get('value').value
        };
        this.company.serviceParameters.push(serviceParam);
      }

      this.saving = true;
      if (this.isNew) {
        this.companiesService.createCompany(this.company).subscribe((res) => {
          this.saving = false;
          const message = `Compañía ${ this.company.name } creada.`;
          this.appService.showSnackBar(message, SnackbarType.SUCCESS);
          this.router.navigate(['/companies']);
        }, (err) => {
          this.saving = false;
          const message = `Error al crear la compañía ${ this.company.name }.`;
          this.appService.showSnackBar(message, SnackbarType.ERROR);
        });
      } else {
        this.companiesService.updateCompany(this.company).subscribe((res) => {
          this.saving = false;
          const message = `Compañía ${ this.company.name } actualizada.`;
          this.appService.showSnackBar(message, SnackbarType.SUCCESS);
          this.router.navigate(['/companies']);
        }, (err) => {
          this.saving = false;
          const message = `Error al actualizar la compañía ${ this.company.name }.`;
          this.appService.showSnackBar(message, SnackbarType.ERROR);
        });
      }
    }
  }

  getServerControls() {
    return (<FormArray> this.companyForm.get('kafkaServers')).controls;
  }

  addServer(server?: string, focus?: boolean) {
    const kafkaServersArray = this.companyForm.get('kafkaServers');
    const serverControl = new FormControl(server || null, Validators.required);
    (<FormArray> kafkaServersArray).push(serverControl);
    if (focus) {
      // TODO set focus
    }
  }

  removeServer(index) {
    (<FormArray> this.companyForm.get('kafkaServers')).removeAt(index);
  }

  getServiceParamsControls() {
    return (<FormArray> this.companyForm.controls.serviceParameters).controls;
  }

  addServiceParameter(serviceParam?: { key: string, value: string }) {
    const key = serviceParam ? serviceParam.key : '';
    const value = serviceParam ? serviceParam.value : '';
    const serviceParamsArray = this.companyForm.get('serviceParameters');
    const serviceParamGroup = new FormGroup({
      key: new FormControl(key),
      value: new FormControl(value)
    });
    (<FormArray> serviceParamsArray).push(serviceParamGroup);
  }

  removeServiceParameter(index) {
    (<FormArray> this.companyForm.get('serviceParameters')).removeAt(index);
  }
}
