import { ConsumerFieldResponse } from './consumer-field-response.model';

export class ConsumerField {
  id: string;
  description: string;
  fieldName: string;

  desrializeConsumerFieldResponse(res: ConsumerFieldResponse) {
    this.id = res.id;
    this.fieldName = res.field_name;
    this.description = res.description;
    return this;
  }

  serializeField() {
    const postData = {};
    postData['field_name'] = this.fieldName;
    postData['description'] = this.description;
    return postData;
  }
}