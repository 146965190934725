import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './auth/auth-guard.service';
import { CompaniesComponent } from './companies/companies.component';
import { CompanyViewComponent } from './companies/company-view/company-view.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ConsumersComponent } from './consumers/consumers.component';
import { ConsumerViewComponent } from './consumers/consumer-view/consumer-view.component';
import { ConsumerFieldsComponent } from './consumer-fields/consumer-fields.component';
import { ConsumerFieldViewComponent } from './consumer-fields/consumer-field-view/consumer-field-view.component';
import { LeasingCompaniesComponent } from './leasing-companies/leasing-companies.component';
import { LeasingCompanyViewComponent } from './leasing-companies/leasing-company-view/leasing-company-view.component';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'companies'
  },
  {
    path: 'companies',
    component: CompaniesComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
    data: { title: 'Compañías' }
  },
  {
    path: 'companies/:id',
    component: CompanyViewComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
    data: { title: 'Compañías' }
  },
  {
    path: 'consumers',
    component: ConsumersComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
    data: { title: 'Integraciones' }
  },
  {
    path: 'consumers/:id',
    component: ConsumerViewComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
    data: { title: 'Integraciones' }
  },
  {
    path: 'consumer-fields',
    component: ConsumerFieldsComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
    data: { title: 'Campos de Integraciones' }
  },
  {
    path: 'consumer-fields/:id',
    component: ConsumerFieldViewComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
    data: { title: 'Campos de Integraciones' }
  },
  {
    path: 'leasing-companies',
    component: LeasingCompaniesComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
    data: { title: 'Arrendadoras' }
  },
  {
    path: 'leasing-companies/:id',
    component: LeasingCompanyViewComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
    data: { title: 'Arrendadoras' }
  },
  {
    path: 'login',
    component: AuthComponent,
    pathMatch: 'full',
    data: { title: 'Iniciar Sesión' }
  },
  {
    path: '404',
    component: PageNotFoundComponent,
    data: { title: 'Página no encontrada' }
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
